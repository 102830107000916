"use client"; // Error components must be Client Components

import { useEffect } from "react";
import { Headline } from "@/components/typography/Headline";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <section className="bg-white flex h-screen items-center">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <Headline as="h1" title="Ein Fehler trat auf" className="mb-4" />
          <p className="mb-4 text-lg font-light text-gray-800">
            Es tut uns leid, aber irgendetwas ist schief gelaufen. Wir wurden
            benachrichtigt und kümmern uns darum.
          </p>

          <button
            type="button"
            className="text-black bg-brand-primary hover:bg-yellow-300 focus:ring-4 focus:ring-brand-secondary font-semibold rounded-md px-5 py-2.5 me-2 mb-2 focus:outline-none"
            onClick={
              // Attempt to recover by trying to re-render the segment
              () => reset()
            }
          >
            Erneut versuchen
          </button>
        </div>
      </div>
    </section>
  );
}
